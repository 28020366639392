import { gql } from '@apollo/client';

export const FullCharger = gql`
  fragment FullCharger on Charger {
    altId
    additionalEquipment
    additionalSerial
    ccReaderType
    certified
    chargePointIdentity
    chargerIsManaged
    chargerModel {
      altId
      modelName
    }
    chargerName
    chargerStatusId
    cid
    communicationMethod
    connectionUri
    coBranding
    description
    disabled
    equipmentId
    evcSyncedAt
    evses {
      edges {
        altId
        evseId
        connectors {
          edges {
            altId
            uniqueConnectorId
            identityKey
            inMaintenance
            connectorType {
              columnText
            }
          }
        }
      }
    }
    excluded
    fieldStationStatus
    fundingSource
    hidden
    identityKey
    ignoreStatusNotifications
    installDate
    ipAddress
    lastReceivedCommSignal
    lastReportedFirmwareVersion
    localLoadBalancingEnabled
    localNetworkMaster
    macAddress
    maintenanceComments
    maintenanceVendorId
    meterSigningSupported
    modelNotes
    mountType
    nextMaintenanceDate
    nonNetworked
    evseId
    connectionDate
    usageStartDate
    maxPower
    excludeFromOcpi
    authenticationModeId
    networkConnectionTypeId
    wirelessConnectionCarrierId
    serialNumber
    showInThirdPartyFilters
    simultaneousChargingEnabled
    site {
      access
      address1
      address2
      administrativeArea
      altId
      hoursOfOperation {
        custom
        from
        to
        monday {
          closed
          timeslots {
            from
            to
          }
        }
        tuesday {
          closed
          timeslots {
            from
            to
          }
        }
        wednesday {
          closed
          timeslots {
            from
            to
          }
        }
        thursday {
          closed
          timeslots {
            from
            to
          }
        }
        friday {
          closed
          timeslots {
            from
            to
          }
        }
        saturday {
          closed
          timeslots {
            from
            to
          }
        }
        sunday {
          closed
          timeslots {
            from
            to
          }
        }
      }
      latitude
      locality
      locationMarket
      longitude
      postalCode
      siteName
      sid
      timeZone
      type
    }
    smartChargingEnabled
    teslaAdapter
    validateMeterSignature
    warehouse {
      altId
      warehouseName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
    }
    warrantyLengthId
    warrantyStatusId
    warrantyStartDate
    warrantyExpirationDate
  }
`;
