import { Formik, FormikHelpers } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetTariffForExtendQuery, useUpsertTariffForExtendMutation } from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { CustomLoaderContainer } from 'src/components/shared/CustomLoader';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { errorMessageHandler, Handlers } from 'src/lib/helpers/errorMessageHandler';
import { transformReservationCustomPricingForExtend } from 'src/lib/helpers/transformReservationPricingForExtend';
import {
  transformTariffCustomPricingForExtend,
  transformTariffDefaultPricingForExtend,
} from 'src/lib/helpers/transformTariffPricingForExtend';
import { useSnackbar } from 'src/lib/hooks';
import styled from 'styled-components';
import { ChargerPriceStepper, Step } from '../ChargerPriceStepper';
import { ChargerPricingForm } from './ChargerPricingForm';
import { FormPricingPricing, FormReservationFees, FormValues, transformTariffValues } from './utils';
import { validationSchema } from './validation';

const Container = styled.div`
  .MuiTab-wrapper {
    flex-direction: row;

    svg {
      margin-left: 5px;
    }
  }
`;

const initialPricingTypes: FormPricingPricing = {
  perKwh: false,
  perSession: false,
  perMinute: false,
};

const initialReservationTerm: FormReservationFees = {
  fee: false,
  noShowFee: false,
  enabled: false,
};

export function ChargerPricesPricingView() {
  const id = 'charger-pricing-view';

  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { altId = '' } = useParams<{ altId: string }>();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const initialValues: FormValues = {
    altId: altId || '',
    chargerGroupIds: [],
    defaultPricing: { pricing: initialPricingTypes },
    customPricing: [],
    reservationTerm: initialReservationTerm,
  };

  const variables = {
    input: { altId },
  };

  const { data } = useGetTariffForExtendQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [upsertTariff] = useUpsertTariffForExtendMutation();

  const priceGroup = data?.getTariff;
  const tariff = priceGroup
    ? {
        altId: priceGroup.altId || '',
        chargerGroupIds: priceGroup?.chargerGroups?.map((group) => group?.altId || '') || [],
        customPricing:
          priceGroup.customPricing?.map((customPricing) => transformTariffCustomPricingForExtend(customPricing)) || [],
        defaultPricing: transformTariffDefaultPricingForExtend(priceGroup.defaultPricing),
        reservationTerm:
          transformReservationCustomPricingForExtend(priceGroup.reservationTerm) || initialReservationTerm,
      }
    : initialValues;

  const handleSubmit = (formValues: FormValues, { resetForm, setValues }: FormikHelpers<FormValues>) => {
    upsertTariff({
      variables: { input: transformTariffValues(formValues) },
      onCompleted: () => {
        navigate(`/extend-plus/charger-prices/${isNew ? `${altId}/details?new=true` : `${altId}/details`}`);
      },
      onError: (error) => {
        const handlers: Handlers[] = [
          {
            codes: ['TARIFF_SCHEDULE_DAY_LIMIT', 'TARIFF_SCHEDULE_WEEK_LIMIT'],
            message: 'You currently have too many price schedules. Try decreasing and try again.',
          },
          {
            codes: ['TARIFF_ITEM_DISPLAY_NAME_UNIQUENESS_VIOLATION'],
            message: 'HMI display names must be unique on a charger price. Please provide unique display names.',
          },
          {
            codes: ['TARIFF_ITEM_DISPLAY_RANK_UNIQUENESS_VIOLATION'],
            message: 'HMI display ranks must be unique on a charger price. Please provide unique display ranks.',
          },
        ];
        snackbar.error(
          errorMessageHandler({
            error,
            handlers,
            defaultMessage: `Sorry, we were unable to ${!altId ? 'create' : 'update'} your Charger Price`,
          }),
        );
        resetForm();
        setValues(formValues);
      },
    });
  };

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-charger-prices-link`} to="/extend-plus/charger-prices">
              Charger Prices
            </BreadcrumbLink>
            <BreadcrumbLink
              data-testid={`${id}-charger-prices-details-link`}
              to={`/extend-plus/charger-prices/${altId}/details`}
            >
              {priceGroup?.tariffName}
            </BreadcrumbLink>
            <BreadcrumbText>Price Details</BreadcrumbText>
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Charger Price Details</Title>
              <SubTitle color="textPrimary">Manage and apply pricing for your charger groups.</SubTitle>
            </>
          }
        />
      }
    >
      <Container>
        <Formik enableReinitialize initialValues={tariff} onSubmit={handleSubmit} validationSchema={validationSchema}>
          <CustomLoaderContainer
            variations={[
              'Creating pricing for your charger group...',
              'Ensuring all charger groups are updated, may take a while...',
              'Still updating ☕️...',
            ]}
            interval={5}
          >
            {isNew && <ChargerPriceStepper currentStep={Step.Prices} />}
            {data && <ChargerPricingForm tariffName={priceGroup?.tariffName} id="charger-prices" />}
          </CustomLoaderContainer>
        </Formik>
      </Container>
    </PageContent>
  );
}
