import { Divider } from '@evgo/react-material-components';
import { Checkbox, FormControlLabel, Tab, Tabs } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConnectorIcon } from '../../../../../svgs/ConnectorIcon';
import { initialValues } from '../../initialValues';
import { ChargerConnectorInfo } from './ChargerConnectorInfo';
import { ChargerConnectorPlansTab } from './ChargerConnectorPlansTab';
import { ChargerConnectorSpecsTab } from './ChargerConnectorSpecsTab';
import { Styled as StyledPaper } from './styles';

export interface Props {
  connectorIndex: number;
  evseIndex: number;
  className?: string;
}

/**
 * Charger connector component
 */
export const ChargerConnector: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerConnector');
  const className = id;
  const { className: parentClass, connectorIndex: j, evseIndex: i } = props;
  const { values, handleChange } = useFormikContext<typeof initialValues>();
  const [tab, setTab] = useState(0);
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  // Handles changing the tab
  const handleChangeTab = useCallback((event, value) => setTab(value), []);

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <ChargerConnectorInfo className={className} id={`${id}-${i}-${j}-header-connector-type`} title="Connector Type">
          <ConnectorIcon
            className={className}
            name={_.get(values, `evses.edges[${i}].connectors.edges[${j}].connectorType.connectorName`)}
            status={_.get(values, `evses.edges[${i}].connectors.edges[${j}].status`)}
          />

          {_.get(values, `evses.edges[${i}].connectors.edges[${j}].connectorType.connectorName`)}
        </ChargerConnectorInfo>

        <ChargerConnectorInfo
          className={className}
          id={`${id}-${i}-${j}-header-connector-tarrif-name`}
          title="Tariff Name"
        >
          {_.get(values, `evses.edges[${i}].connectors.edges[${j}].tariff.name`)}
        </ChargerConnectorInfo>

        <ChargerConnectorInfo className={className} column title="Active Session">
          <span className={className}>
            ID:&nbsp;
            <Link
              className={className}
              id={`${id}-${i}-${j}-header-connector-active-session`}
              to={`/sessions/${_.get(values, `evses.edges[${i}].connectors.edges[${j}].session.altId`)}`}
            >
              {_.get(values, `evses.edges[${i}].connectors.edges[${j}].session.altId`, '1234567')}
            </Link>
          </span>

          <span id={`${id}-${i}-${j}-header-connector-active-driver`} className={className}>
            Driver:{' '}
            {_.get(values, `evses.edges[${i}].connectors.edges[${j}].session.driver.driverName`, 'Placeholder Jones')}
          </span>
        </ChargerConnectorInfo>

        <FormControlLabel
          className={className}
          control={
            <Checkbox
              checked={_.get(values, `evses.edges[${i}].connectors.edges[${j}].inMaintenance`)}
              name={`evses.edges[${i}].connectors.edges[${j}].inMaintenance`}
              className={className}
              inputProps={{ id: `${id}-${i}-${j}-header-connector-in-maintenance` }}
              onChange={handleChange}
            />
          }
          label="In Maintenance"
        />
      </header>

      <Divider />

      <Tabs
        className={className}
        id={`${id}-${i}-${j}-tabs`}
        onChange={handleChangeTab}
        TabIndicatorProps={{ className }}
        value={tab}
      >
        <Tab className={className} label="Connector Specs" />
        <Tab className={className} label="Plans" />
      </Tabs>

      <Divider />

      {tab === 0 && <ChargerConnectorSpecsTab className={className} connectorIndex={j} portIndex={i} />}

      {tab === 2 && <ChargerConnectorPlansTab className={className} connectorIndex={j} portIndex={i} />}
    </StyledPaper>
  );
};
