/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryResult, useLazyQuery, useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { List, PinDrop } from '@material-ui/icons';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ChargersWithMeta, Maybe, Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listChargers } from '../../../apollo/queries/chargers';
import { listChargerModelOptions } from '../../../apollo/queries/models';
import { listMappedSites } from '../../../apollo/queries/sites';
import { sanitizeSearch, updateQuery } from '../../../lib/helpers';
import { ListSearch as ChargersListSearch } from '../../shared/ListSearch';
import { ChargersHeader } from './ChargersHeader';
import { ChargersList } from './ChargersList';
import { ChargersListFilters } from './ChargersListFilters';
import { ChargersMap } from './ChargersMap';
import { ChargersNetworkStatus } from './ChargersNetworkStatus';
import { Styled } from './styles';

interface Filter {
  [key: string]: {
    in?: Array<string | number>;
    or?: any[];
  };
}

const searchFields = [
  'cid',
  'chargerName',
  'site_address1',
  'site_locality',
  'site_administrativeArea',
  'site_postalCode',
  'site_property_propertyName',
];

/**
 * Gets list with up-to-date sort info
 */
export const onSort =
  (fetchMore: any, metadata: ChargersWithMeta, key: any): any =>
  (): any => {
    const { filter, pageSize, search, total } = metadata;
    const omitPaths = ['chargerModel_altId.__typename', 'fieldStationStatus.__typename', 'tags.__typename'];
    let sort = { [key]: 'ASC' };

    if (_.get(metadata, `sort.${key}`, 'ASC') === 'ASC') sort = { [key]: 'DESC' };
    let formattedFilter = _.pickBy(
      _.pick(_.omit(filter, omitPaths), ['chargerModel_altId', 'fieldStationStatus', 'tags']),
      (i) => i !== null,
    ) as Filter;

    if (Object.keys(formattedFilter)[0] === 'chargerModel_altId') {
      formattedFilter = {
        ...formattedFilter,
        chargerModel_altId: { in: (formattedFilter?.chargerModel_altId?.in || []).map(Number) },
      };
    }

    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: {
          page: 0,
          pageSize,
          total,
          sort,
          filter: formattedFilter,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Gets list with up-to-date offset info
 */
export const onChangePage =
  (fetchMore: any, metadata: ChargersWithMeta): any =>
  (event: any, page: any): any => {
    const { filter, pageSize, sort, search } = metadata;
    const omitPaths = ['chargerModel_altId.__typename', 'fieldStationStatus.__typename', 'tags.__typename'];

    let formattedFilter: Filter = _.pickBy(
      _.pick(_.omit(filter, omitPaths), ['chargerModel_altId', 'fieldStationStatus', 'tags']),
      (i) => i !== null,
    ) as any;

    if (Object.keys(formattedFilter)[0] === 'chargerModel_altId') {
      formattedFilter = {
        ...formattedFilter,
        chargerModel_altId: { in: (formattedFilter?.chargerModel_altId?.in || []).map(Number) },
      };
    }

    // TODO: filter === 'tags'  this doesn't make any sense
    // if (!_.isEmpty(formattedFilter, 'tags.or') && _.map(Object.keys(formattedFilter), filter === 'tags')) {
    //   formattedFilter = {
    //     ...formattedFilter,
    //     tags: { or: _.map(_.get(formattedFilter, 'tags.or', []), (tag) => _.omit(tag, '__typename')) },
    //   };
    //   if (_.isEmpty(formattedFilter.tags.or)) formattedFilter = _.omit(formattedFilter, 'tags');
    // }

    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: {
          page,
          pageSize,
          sort: _.pickBy(
            _.pick(sort, [
              'cid',
              'altId',
              'chargerName',
              'fieldStationStatus',
              'propertyName',
              'chargerModel_modelName',
            ]),
            (i) => i !== null,
          ),
          filter: formattedFilter,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Gets list with up-to-date limit info
 */
export const onChangeRowsPerPage =
  (fetchMore: any, metadata: ChargersWithMeta): any =>
  (event: any): any => {
    const { filter, search, sort } = metadata;
    const omitPaths = ['chargerModel_altId.__typename', 'fieldStationStatus.__typename', 'tags.__typename'];

    let formattedFilter: Filter = _.pickBy(
      _.pick(_.omit(filter, omitPaths), ['chargerModel_altId', 'fieldStationStatus', 'tags']),
      (i) => i !== null,
    ) as any;

    if (Object.keys(formattedFilter)[0] === 'chargerModel_altId') {
      formattedFilter = {
        ...formattedFilter,
        chargerModel_altId: { in: (formattedFilter?.chargerModel_altId?.in || []).map(Number) },
      };
    }

    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: {
          page: 0,
          pageSize: event.target.value,
          sort: _.pickBy(
            _.pick(sort, [
              'cid',
              'altId',
              'chargerName',
              'fieldStationStatus',
              'propertyName',
              'chargerModel_modelName',
            ]),
            (i) => i !== null,
          ),
          filter: formattedFilter,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Chargers view component.
 */
export const ChargersView: React.FC = () => {
  const location = useLocation();
  const className = 'chargersview-component';
  const mapView = location.pathname === '/chargers/map';
  /** Local state object containing map information. */
  const [state, setState] = useState({
    radius: 40,
    center: {
      // Los Angeles
      latitude: 34.0522,
      longitude: -118.2437,
    },
  });

  const [checked, setChecked] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState(false);

  const [chargerInputs, setChargerInputs] = useState<{
    page?: number;
    pageSize?: number;
    sort?: any;
    filter?: Filter;
    search?: any;
  }>({});

  const {
    data: chargerList,
    fetchMore: chargersFetchMore,
    loading: chargersLoading,
  } = useQuery<Query>(listChargers, {
    fetchPolicy: 'network-only',
    variables: {
      chargersInput: {
        page: 0,
        pageSize: 10,
        sort: { chargerName: 'ASC' },
      },
    },
    skip: mapView,
  });

  const [listSites, { data: siteList, fetchMore: sitesFetchMore, loading: sitesLoading }] = useLazyQuery(
    listMappedSites,
    {
      fetchPolicy: 'network-only',
    },
  );

  /** Map ref */
  const mapRef = useRef<any>();

  /**
   * Takes latitude and longitude of two locations and returns the radius between them in miles.
   */
  const calcDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
    const rad = (num: number) => (Math.PI * num) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    const dist =
      Math.sin(rad(lat1)) * Math.sin(rad(lat2)) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.cos(radtheta);

    return Math.floor(((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515) || 1;
  };

  /**
   * This function saves a reference of it to be used to get center and bounds of the map.
   */
  const onMount = () => {
    if (mapRef?.current) {
      const bounds = mapRef.current?.state?.map?.getBounds?.();
      const center = mapRef.current?.state?.map?.getCenter?.();

      if (bounds && center) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const centerLat = parseFloat(center.lat());
        const centerLng = parseFloat(center.lng());
        const neLat = parseFloat(ne.lat());
        const neLng = parseFloat(ne.lng());
        const swLat = parseFloat(sw.lat());
        const swLng = parseFloat(sw.lng());
        const radius = calcDistance(centerLat, centerLng, neLat, neLng);

        if (state.radius !== radius) setState((prevState) => ({ ...prevState, radius }));
        const coords = { centerLat, centerLng, neLat, neLng, swLat, swLng };

        const inputs = {
          page: 0,
          pageSize: 999,
          filter: { ...coords },
        };

        listSites({
          variables: {
            sitesInput: inputs,
            chargersInput: chargerInputs,
          },
        });
      }
    }
  };

  /** This function listens for zoom event on the map and updates radius. */
  const onZoom = () => {
    if (mapRef && mapRef.current) {
      const bounds = mapRef.current.state.map.getBounds();
      const center = mapRef.current.state.map.getCenter();

      if (bounds && center) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const centerLat = parseFloat(center.lat());
        const centerLng = parseFloat(center.lng());
        const neLat = parseFloat(ne.lat());
        const neLng = parseFloat(ne.lng());
        const swLat = parseFloat(sw.lat());
        const swLng = parseFloat(sw.lng());
        const radius = calcDistance(centerLat, centerLng, neLat, neLng);

        if (state.radius !== radius) {
          const coords = { centerLat, centerLng, neLat, neLng, swLat, swLng };
          const inputs = {
            page: 0,
            pageSize: 999,
            filter: { ...coords },
          };

          setState({ center: { latitude: centerLat, longitude: centerLng }, radius });
          listSites({
            variables: {
              sitesInput: inputs,
              chargersInput: chargerInputs,
            },
          });
        }
      }
    }
  };

  /** This function listens for center change events on the map and updates center and chargers. */
  const onDrag = () => {
    if (mapRef && mapRef.current) {
      const bounds = mapRef.current.state.map.getBounds();
      const center = mapRef.current.state.map.getCenter();

      if (bounds && center) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const centerLat = parseFloat(center.lat());
        const centerLng = parseFloat(center.lng());
        const neLat = parseFloat(ne.lat());
        const neLng = parseFloat(ne.lng());
        const swLat = parseFloat(sw.lat());
        const swLng = parseFloat(sw.lng());

        if (state.center.latitude !== centerLat || state.center.longitude !== centerLng) {
          const coords = { centerLat, centerLng, neLat, neLng, swLat, swLng };
          const inputs = {
            page: 0,
            pageSize: 999,
            filter: { ...coords },
          };

          setState((prevState) => ({ ...prevState, center: { latitude: centerLat, longitude: centerLng } }));
          listSites({
            variables: {
              sitesInput: inputs,
              chargersInput: { ...chargerInputs, pageSize: 999 },
            },
          });
        }
      }
    }
  };

  let listData = chargerList;
  let listFetchMore: any = chargersFetchMore;
  let listLoading = chargersLoading;
  // TODO: listMappedSites was removed
  const { ...metadata } = _.get(listData, mapView ? 'listMappedSites' : 'listChargers', {}) as ChargersWithMeta;
  let chargerCount = metadata.total;

  if (mapView) {
    listData = siteList;
    listFetchMore = sitesFetchMore;
    listLoading = sitesLoading;
    chargerCount = listData?.listSites?.edges?.reduce((sum, v) => {
      return sum + (v?.chargers?.total || 0);
    }, 0);
  }

  /**
   * Search Chargers list
   */
  const onSearchChange = (
    fetchMore: QueryResult<never, never>['fetchMore'],
    meta: ChargersWithMeta,
    target: EventTarget & HTMLInputElement,
  ) => {
    // const { filter } = meta;
    const omitPaths = ['chargerModel_altId.__typename', 'fieldStationStatus.__typename', 'tags.__typename'];
    let search: any = {};
    if (target.value.length) {
      searchFields.forEach((field) => {
        search[field] = { iLike: `%${target.value}%` };
      });
    } else {
      search = null;
    }

    let formattedFilter: Filter = _.pickBy(
      _.pick(_.omit(chargerInputs.filter, omitPaths), ['chargerModel_altId', 'fieldStationStatus', 'tags']),
      (i) => i !== null,
    ) as any;

    if (Object.keys(formattedFilter)[0] === 'chargerModel_altId') {
      formattedFilter = {
        ...formattedFilter,
        chargerModel_altId: { in: (formattedFilter?.chargerModel_altId?.in || []).map(Number) },
      };
    }

    const inputs = {
      page: chargerInputs.page,
      pageSize: mapView ? 999 : chargerInputs.pageSize,
      sort: _.pickBy(_.omit(chargerInputs.sort, '__typename'), _.identity),
      filter: formattedFilter,
      search,
    } as any;
    setChargerInputs(inputs);

    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: inputs,
      },
    });
  };

  /**
   * Filter Chargers list
   */
  const updateFilters = (
    fetchMore: QueryResult<never, never>['fetchMore'],
    meta: ChargersWithMeta,
    newFilters: any,
    key: string,
  ) => {
    const { pageSize, filter, sort } = meta;

    let modelFilters: Maybe<Filter> = { chargerModel_altId: { in: _.get(newFilters, 'chargerModel_altId', []) } };
    let statusFilters: Maybe<Filter> = { fieldStationStatus: { in: _.get(newFilters, 'fieldStationStatus', []) } };

    if (key === 'chargerModel_altId') {
      modelFilters = { chargerModel_altId: { in: _.get(newFilters, 'chargerModel_altId', []) } };
      statusFilters = { fieldStationStatus: { in: _.get(filter, 'fieldStationStatus.in', []) } };
    }

    if (key === 'fieldStationStatus') {
      modelFilters = { chargerModel_altId: { in: _.get(filter, 'chargerModel_altId.in', []) } };
      statusFilters = { fieldStationStatus: { in: _.get(newFilters, 'fieldStationStatus', []) } };
    }

    if (_.isEmpty(modelFilters.chargerModel_altId.in)) modelFilters = null;
    if (_.isEmpty(statusFilters.fieldStationStatus.in)) statusFilters = null;

    const shapedFilters = { ...modelFilters, ...statusFilters };
    const inputs = {
      page: 0,
      pageSize: mapView ? 999 : pageSize,
      sort: _.pickBy(
        _.pick(sort, ['cid', 'altId', 'chargerName', 'fieldStationStatus', 'propertyName', 'chargerModel_modelName']),
        (i) => i !== null,
      ),
      filter: shapedFilters,
      search: sanitizeSearch(chargerInputs.search, searchFields),
    } as any;
    setChargerInputs(inputs);

    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: inputs,
      },
    });
  };

  let view = (
    <ChargersList
      listData={listData as Query}
      loading={listLoading}
      onSort={(key) => {
        setAllChecked(false);
        setChecked([]);
        onSort(listFetchMore, metadata, key)();
      }}
      onChangePage={(e, page) => {
        setAllChecked(false);
        setChecked([]);
        onChangePage(listFetchMore, metadata)(e, page);
      }}
      onChangeRowsPerPage={(e) => {
        setAllChecked(false);
        setChecked([]);
        onChangeRowsPerPage(listFetchMore, metadata)(e);
      }}
      checked={checked}
      setChecked={setChecked}
      allChecked={allChecked}
      setAllChecked={setAllChecked}
    />
  );
  let activeIcon = 'list';
  if (mapView) {
    view = (
      <ChargersMap
        center={state.center}
        mapRef={mapRef}
        onMount={onMount}
        onZoom={onZoom}
        onDrag={onDrag}
        listData={listData as Query}
        loading={listLoading}
      />
    );
    activeIcon = 'map';
  }

  const debouncedOnSearchChange = (
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange
  ) as typeof onSearchChange;

  const { data, loading } = useQuery<Query>(listChargerModelOptions);

  const models: LabelValue<string>[] =
    !loading && data?.listChargerModelOptions
      ? data.listChargerModelOptions.map((modelOptions) => {
          return {
            label: modelOptions?.modelName as string,
            value: modelOptions?.altId as string,
          };
        })
      : [];

  return (
    <Styled className={className}>
      <ChargersHeader metadata={metadata} fetchmore={listFetchMore} />
      <ChargersNetworkStatus className={className} />
      <Paper className={className} component="section">
        <header className={className}>
          <div className={`${className} container`}>
            <div className={`${className} title`}>
              <Typography className={className} variant="h6" component="h2">
                Chargers
              </Typography>
              <Typography className={className} variant="caption">
                Currently viewing {listLoading ? '...' : chargerCount}
              </Typography>
            </div>

            <div className={`${className} controls`}>
              <ChargersListSearch
                className={className}
                type="charger"
                onSearchChange={(search) => {
                  setAllChecked(false);
                  setChecked([]);
                  debouncedOnSearchChange(listFetchMore, metadata, search);
                }}
              />

              <Button className={className} to="/chargers/list" component={NavLink} variant="text">
                <List className={`${className} ${activeIcon === 'list' ? 'active' : ''}`} color="secondary" />
              </Button>

              <Button className={className} to="/chargers/map" component={NavLink} variant="text">
                <PinDrop className={`${className} ${activeIcon === 'map' ? 'active' : ''}`} />
              </Button>
            </div>
          </div>

          <ChargersListFilters
            updateFilters={(newFilters, key) => {
              setAllChecked(false);
              setChecked([]);
              updateFilters(listFetchMore, metadata, newFilters, key);
            }}
            className={`${className} filters`}
            models={models}
          />
        </header>

        {listLoading ? (
          <LinearProgress className={className} color="primary" />
        ) : (
          <div className={className} style={{ height: '4px' }} />
        )}

        <Divider />

        <div className={className}>{view}</div>
      </Paper>
    </Styled>
  );
};
