import { useMutation } from '@apollo/client';
import { Divider, Dropdown, AccordionActions, TextInfo } from '@evgo/react-material-components';
import {
  Button,
  IconButton,
  Paper,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Connector, Driver, Maybe, useListDriversV2LazyQuery } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { startCharge } from 'src/apollo/queries/chargers/startCharge';
import { formatUSNumber } from 'src/lib/helpers';
import { initialValues } from '../initialValues';
import { Styled as StyledModal } from './styles';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { useModalContext } from 'src/contexts/ModalContext';
export interface Props {
  open: boolean;
  setStartCharge: (value: boolean) => void;
  evses: (typeof initialValues)['evses'];
  className?: string;
}

const steps = ['Connector', 'Customer', 'Confirm and Start'];

const stepContents = ['Select Connector', 'Select Customer', 'Confirm and Start'];

/**
 * Start Charger component.
 */
export const StartCharge: React.FC<Props> = (props) => {
  const className = 'StartCharge';
  const { setStartCharge, evses } = props;
  let connectorOptions: LabelValue<string>[] = [];
  if (!_.isEmpty(evses) && !_.isEmpty(evses.edges)) {
    connectorOptions = _.reduce(
      evses.edges,
      (acc, p) => {
        return p.connectors.edges.concat(acc);
      },
      [] as Connector[],
    ).map((c) => ({ value: c.altId, label: c.connectorType?.columnText || '' }));
  }
  const [listDriversV2Query, { data: customersSearchData, loading }] = useListDriversV2LazyQuery({
    fetchPolicy: 'no-cache',
  });
  let customerOptions: Maybe<Maybe<Driver>[]> | undefined;
  let customerTotal = 0;

  if (!loading) {
    customerOptions = customersSearchData?.listDriversV2?.edges || [];
    customerTotal = customersSearchData?.listDriversV2?.total || 0;
  }

  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedConnector, setSelectedConnector] = React.useState<string | null>();
  const [selectedCustomer, setSelectedCustomer] = React.useState<string | null>();
  const [customerInput, setCustomerInput] = React.useState('');
  const [showCustomerOptions, setShowCustomerOptions] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [startChargeMutation, { data: chargerCommandData, errors: chargerCommandErrors }] = useMutation<any>(
    startCharge,
    {
      onError: (errors) => {
        setModalState({ modalName: '', modalProps: {}, modalVisible: false });
        snackbar.error(_.get(errors, 'graphQLErrors')[0].message || 'The session failed to start.');
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  const onSearchClick = () => {
    setSelectedCustomer(null);
    setShowCustomerOptions(true);
    listDriversV2Query({ variables: { driversInput: { search: { email: { iLike: `%${customerInput}%` } } } } });
  };

  const onCancelClick = () => {
    setActiveStep(0);
    setSelectedConnector(null);
    setSelectedCustomer(null);
    setCustomerInput('');
    setShowCustomerOptions(false);
    setStartCharge(false);
  };

  const startChargeCommand = () => {
    const connectorId = selectedConnector;
    setModalState({ modalName: 'StartChargeModal', modalProps: {}, modalVisible: true });

    startChargeMutation({
      variables: {
        input: {
          driverId: selectedCustomer,
          connectorId,
        },
      },
    });
  };

  useEffect(() => {
    if (chargerCommandData && chargerCommandData.startCharge === null) {
      setModalState({ modalName: '', modalProps: {}, modalVisible: false });

      if (_.get(chargerCommandErrors, '[0]')) {
        snackbar.error('The session failed to start.');
      } else {
        onCancelClick();
        snackbar.success('The session has been successfully started.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerCommandData]);

  const shouldNextButtonDisabled = () => {
    if (activeStep === 0 && selectedConnector) {
      return false;
    }

    if (activeStep === 1 && selectedCustomer) {
      return false;
    }

    if (activeStep === 2) {
      return false;
    }

    return true;
  };

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledModal open={props.open} onClose={() => props.setStartCharge(false)} className={className}>
      <div className={`${className} modal-content`}>
        <Paper className={`${customClass} start-charge`} component="section">
          <header className={className}>
            <Typography className={className} variant="h6" component="h2">
              Start Charge
            </Typography>
            <IconButton className={className} onClick={() => onCancelClick()}>
              <Close />
            </IconButton>
          </header>
          <Divider className={className} />

          <div className={`${className} steps`}>
            <div className={className}>
              <Typography className={className} variant="body1">
                {stepContents[activeStep]}
              </Typography>
            </div>
            <Stepper activeStep={activeStep} alternativeLabel className={`${className} stepper`}>
              {steps.map((label, step) => {
                return (
                  <Step key={label} className={`${className} ${step > activeStep ? '' : 'active'}`}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className={className}></div>
          </div>

          {activeStep === 0 ? (
            <div className={`${className} search`}>
              <Dropdown
                className={`${className} search-dropdown`}
                options={connectorOptions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedConnector(e.target.value)}
                label="Connector"
                value={selectedConnector}
              />
            </div>
          ) : null}

          {activeStep === 1 ? (
            <div className={`${className} search`}>
              <div className={`${className} search-input`}>
                <TextInfo
                  className={className}
                  label="Customer"
                  value={customerInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerInput(e.target.value)}
                  helperText="Search by email"
                  variant="filled"
                />

                <Button
                  className={className}
                  size="large"
                  color="secondary"
                  variant="contained"
                  disabled={!customerInput.trim()}
                  onClick={onSearchClick}
                >
                  ENTER
                </Button>
              </div>

              {showCustomerOptions ? (
                <Paper className={`${className} search-table`}>
                  <Table className={className}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Phone Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading && !customerTotal && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography className={className} variant="body2">
                              No customers found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {!loading &&
                        !!customerTotal &&
                        customerTotal <= 10 &&
                        customerOptions?.map((row, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Radio
                                checked={selectedCustomer === row?.altId}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setSelectedCustomer(e.target.value)
                                }
                                value={row?.altId}
                                color="default"
                              />
                            </TableCell>
                            <TableCell>{`${row?.firstName} ${row?.lastName}`}</TableCell>
                            <TableCell>{_.get(row, 'email', '')}</TableCell>
                            <TableCell>{formatUSNumber(_.get(row, 'phone', ''))}</TableCell>
                          </TableRow>
                        ))}

                      {!loading && !!customerTotal && customerTotal > 10 && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography className={className} variant="body2">
                              Too many results returned, please enter a more specific search.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              ) : null}
            </div>
          ) : null}

          {activeStep === 2 ? (
            <div className={`${className} confirmation`}>
              <div className={className}>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Connector Type
                  </Typography>
                  <Typography className={className} variant="body1">
                    {`${(_.find(connectorOptions, { value: selectedConnector }) as LabelValue<string>)?.label}`}
                  </Typography>
                </div>
              </div>
              <div className={className}>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Customer
                  </Typography>
                  <Typography className={className} variant="body1">
                    {`${(_.find(customerOptions, { altId: selectedCustomer }) as Driver)?.firstName}
                      ${(_.find(customerOptions, { altId: selectedCustomer }) as Driver)?.lastName}`}
                  </Typography>
                </div>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Email Address
                  </Typography>
                  <Typography className={className} variant="body1">
                    {(_.find(customerOptions, { altId: selectedCustomer }) as Driver)?.email}
                  </Typography>
                </div>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Phone Number
                  </Typography>
                  <Typography className={className} variant="body1">
                    {formatUSNumber((_.find(customerOptions, { altId: selectedCustomer }) as Driver)?.phone)}
                  </Typography>
                </div>
              </div>
              <div className={className}></div>
            </div>
          ) : null}

          <Divider />

          <AccordionActions className={`${className} panel-actions`}>
            <Button className={className} size="large" color="primary" variant="text" onClick={onCancelClick}>
              CANCEL
            </Button>

            <Button
              className={className}
              size="large"
              color="secondary"
              variant="contained"
              onClick={activeStep === 2 ? startChargeCommand : () => setActiveStep(activeStep + 1)}
              disabled={shouldNextButtonDisabled()}
            >
              {activeStep === 2 ? 'START' : 'NEXT'}
            </Button>
          </AccordionActions>
        </Paper>
      </div>
    </StyledModal>
  );
};
