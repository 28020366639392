import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import {
  Checkbox,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect } from 'react';
import { Navigate, NavigateFunction, useLocation, useParams } from 'react-router-dom';
import { Query, Tag } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listChargerModelOptions } from 'src/apollo/queries/models';
import { listFalconConstraints } from 'src/apollo/queries/options';
import { listSiteOptions, listWarehouseOptions } from 'src/apollo/queries/sites';
import { useBlurDropdown } from 'src/lib/hooks';
import { ChargerModelView } from '../../ChargerModelView';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';
import { useSnackbarContext } from 'src/contexts/SnackBarContext';

export interface Props {
  expanded?: boolean;
  className?: string;
}

// Sample Charger Statuses, will eventually be pulled from server
const statuses = [
  { label: 'Ordered', value: 'ORDERED' },
  { label: 'In Stock', value: 'IN_STOCK' },
  { label: 'Warehouse Testing', value: 'WAREHOUSE_TESTING' },
  { label: 'Ready for Installation', value: 'READY_FOR_INSTALLATION' },
  { label: 'Connected', value: 'CONNECTED' },
  { label: 'Site Testing', value: 'SITE_TESTING' },
  { label: 'Provisioned', value: 'PROVISIONED' },
];

// Sample Charger Locations, will eventually be pulled from server
const locations = [
  { label: 'Warehouse', value: 'Warehouse' },
  { label: 'Site', value: 'Site' },
];

const fundingSources = [
  { label: 'BAAQMD', value: 'BAAQMD' },
  { label: 'CA Settlement', value: 'CA Settlement' },
  { label: 'CA Settlement Rnd', value: 'CA Settlement Rnd' },
  { label: 'CEC 2014', value: 'CEC 2014' },
  { label: 'CEC 2015', value: 'CEC 2015' },
  { label: 'CEC 2016', value: 'CEC 2016' },
  { label: 'Green Mountain Power', value: 'Green Mountain Power' },
  { label: 'Host Funded', value: 'Host Funded' },
  { label: 'MBARD', value: 'MBARD' },
  { label: 'NEDO', value: 'NEDO' },
  { label: 'NNA EVA', value: 'NNA EVA' },
  { label: 'NNA JMA', value: 'NNA JMA' },
  { label: 'EVgo Funded', value: 'EVgo Funded' },
  { label: 'SANBAG 2016', value: 'SANBAG 2016' },
  { label: 'SCAQMD', value: 'SCAQMD' },
  { label: 'VW-EA', value: 'VW_EA' },
  { label: 'TBD', value: 'TBD' },
];

const flags = [
  { label: 'Hidden', key: 'hidden' },
  { label: 'Disabled', key: 'disabled' },
  { label: 'Excluded', key: 'excluded' },
  { label: 'Exclude From OCPI', key: 'excludeFromOcpi' },
];

const additionalDetails = [
  { label: 'Meter Signing Supported', key: 'meterSigningSupported' },
  { label: 'Show in 3rd Party Filters', key: 'showInThirdPartyFilters' },
  { label: 'Validated Meter Signature', key: 'validateMeterSignature' },
  { label: 'Non-Networked', key: 'nonNetworked' },
  { label: 'Ignore Status Notifications', key: 'ignoreStatusNotifications' },
  { label: 'Smart Charging Enabled', key: 'smartChargingEnabled' },
  { label: 'Local Load Balancing Enabled', key: 'localLoadBalancingEnabled' },
  { label: 'Simultaneous Charging', key: 'simultaneousChargingEnabled' },
];

/**
 * Navigates to appropiate route based on panel expansion
 */
export const onPanelChange = (navigate: NavigateFunction, altId: string, section: string) => {
  return (event: React.ChangeEvent<HTMLInputElement>, expanded: boolean): void => {
    if (altId) {
      if (expanded) navigate(`/chargers/${altId}/${section}`, { replace: true });
      else navigate(`/chargers/${altId}`, { replace: true });
    }
  };
};

/**
 * Updates location state
 */
export const updateLocation =
  (values: typeof initialValues, setValues: (vals: typeof initialValues) => void) =>
  ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    if (target.value === 'Warehouse') {
      setValues({ ...values, site: null, warehouse: null, locationId: '', locationType: 'warehouse' });
    } else {
      setValues({ ...values, site: null, warehouse: null, locationId: '', locationType: 'site' });
    }
  };

/**
 * Charger Overview component
 */
export const ChargerOverview: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerOverview');
  const className = id;
  const { errors, handleBlur, handleChange, isSubmitting, setValues, setFieldValue, touched, values } =
    useFormikContext<typeof initialValues>();

  const { altId, url } = useParams<{ altId: string; url: string }>();
  const { state: preset } = useLocation();

  const handleBlurDropdown = useBlurDropdown();
  const { setSnackbarState } = useSnackbarContext();

  useEffect(() => {
    return () =>
      setSnackbarState({
        snackMessage: '',
        snackVisible: false,
        snackbarType: 'system',
      });
  }, [setSnackbarState]);

  useEffect(() => {
    if (preset) {
      setFieldValue('site.altId', _.get(preset, 'altId', ''));
      setFieldValue('locationId', _.get(preset, 'altId', ''));
    }
  }, [preset, setFieldValue]);

  const { data: warehouseList } = useQuery<Query>(listWarehouseOptions);
  const { data: sitesList } = useQuery<Query>(listSiteOptions);
  const { data: chargerModelsList } = useQuery<Query>(listChargerModelOptions);
  const { data: filteredStatuses, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'chargers',
          },
          columnName: {
            eq: 'authentication_mode_id',
          },
        },
        sort: {
          id: 'ASC',
          columnText: 'ASC',
        },
      },
    },
  });

  let shapedStatusOptions: LabelValue<number>[] = [];
  if (!statusOptionsLoading) {
    shapedStatusOptions =
      filteredStatuses?.listFalconConstraints?.edges?.map((c) => ({
        label: c?.columnText as string,
        value: Number(c?.id),
      })) || [];
  }

  const modelOptions = _.get(chargerModelsList, 'listChargerModelOptions', []);
  const currentChargerModelDetails =
    values && values.chargerModel && modelOptions
      ? _.head(_.filter(modelOptions, (o) => o?.altId === values.chargerModel.altId))
      : { simultaneousChargeSupported: false };

  const siteOptions = _.get(sitesList, 'listSiteOptions', []);
  const warehouseOptions = _.get(warehouseList, 'listWarehouseOptions', []);

  const renderTag = ({ tagName }: Tag & { tagName?: string }, index: number) => (
    <Grid className={`${className} tag`} item xs={2} key={index}>
      <Tooltip title={tagName || ''} placement="top">
        <Paper className={`${className} tag-paper`}>
          <Typography className={className} component="h4" noWrap>
            {tagName}
          </Typography>
        </Paper>
      </Tooltip>
    </Grid>
  );

  const onUpdateChargerModel = useCallback(
    (value) => {
      const [newChargerModel] = _.filter(modelOptions, (option) => option?.altId === value);
      setFieldValue('simultaneousChargingEnabled', false);
      setFieldValue('chargerModel', newChargerModel);
    },
    [setFieldValue, modelOptions],
  );

  const onUpdateSite = useCallback(
    (value) => {
      const [newSite] = _.filter(siteOptions, (option) => option?.altId === value);
      setFieldValue('site', newSite);
      setFieldValue('locationId', value);
    },
    [setFieldValue, siteOptions],
  );

  const onUpdateWarehouse = useCallback(
    (value) => {
      const [newWarehouse] = _.filter(warehouseOptions, (option) => option?.altId === value);
      setFieldValue('warehouse', newWarehouse);
      setFieldValue('locationId', value);
    },
    [setFieldValue, warehouseOptions],
  );

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;
  if (!altId) customClass += ' new-charger';
  if (_.isEmpty(_.get(values, 'site'))) customClass += ' charger-profile';
  if (url === '/chargers/new' && altId) {
    return <Navigate to={`/chargers/${altId}/profile`} />;
  }

  return (
    <StyledAccordion
      className={`${customClass} charger-overview`}
      expanded={props.expanded}
      defaultExpanded={true}
      onChange={onPanelChange}
    >
      <AccordionSummary
        className={className}
        expandIcon={altId ? <ExpandMore className={className} /> : null}
        component="header"
      >
        <Typography className={className} variant="h6" component="h2">
          Overview
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <TextInfo
              id="chargerName"
              className={`${className} thirds`}
              error={!!(_.get(touched, 'chargerName') && _.get(errors, 'chargerName'))}
              helpertext={_.get(touched, 'chargerName') && _.get(errors, 'chargerName')}
              name="chargerName"
              label="Name *"
              labelProps={{ className }}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={_.get(values, 'chargerName')}
            />

            <TextInfo
              id="cid"
              className={`${className} thirds`}
              error={!!(_.get(touched, 'cid') && _.get(errors, 'cid'))}
              helpertext={_.get(touched, 'cid') && _.get(errors, 'cid')}
              label="CID *"
              labelProps={{ className }}
              name="cid"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                setFieldValue('evseId', e.target.value);
              }}
              required
              value={_.get(values, 'cid')}
            />

            <Dropdown
              id="fieldStationStatus"
              className={`${className} status thirds`}
              disabled={isSubmitting}
              error={!!(_.get(touched, 'fieldStationStatus') && _.get(errors, 'fieldStationStatus'))}
              helpertext={_.get(touched, 'fieldStationStatus') && _.get(errors, 'fieldStationStatus')}
              label="Status *"
              labelProps={{ className }}
              name="fieldStationStatus"
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={statuses}
              required
              value={_.get(_.find(statuses, { value: _.get(values, 'fieldStationStatus') }), 'value')}
            />

            <TextInfo
              id="serialNumber"
              className={`${className} thirds`}
              error={!!(_.get(touched, 'serialNumber') && _.get(errors, 'serialNumber'))}
              helpertext={_.get(touched, 'serialNumber') && _.get(errors, 'serialNumber')}
              label="Serial Number *"
              labelProps={{ className }}
              name="serialNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={_.get(values, 'serialNumber')}
            />

            <TextInfo
              id="additional-serial-number"
              className={`${className} thirds`}
              label="Additional Serial Number"
              labelProps={{ className }}
              name="additionalSerial"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'additionalSerial')}
            />

            <TextInfo
              className={`${className} thirds`}
              disabled={isSubmitting}
              error={!!(_.get(touched, 'installDate') && _.get(errors, 'installDate'))}
              helpertext={_.get(touched, 'installDate') && _.get(errors, 'installDate')}
              id={`${id}-connection-date-input`}
              label="Installation Date"
              labelProps={{ className: `${className} prefilled` }}
              name="installDate"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
              type="date"
              value={_.get(values, 'installDate')}
            />

            <TextInfo
              id={`${id}-evse-id`}
              className={`${className} thirds`}
              error={!!(_.get(touched, 'evseId') && _.get(errors, 'evseId')) || !!_.get(errors, 'evse_id')}
              helpertext={(_.get(touched, 'evseId') && _.get(errors, 'evseId')) || _.get(errors, 'evse_id')}
              label="EVSE ID *"
              labelProps={{ className }}
              name="evseId"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (_.isEmpty(e.target.value)) {
                  setFieldValue('evseId', _.get(values, 'cid'));
                } else {
                  handleChange(e);
                }
              }}
              value={_.get(values, 'evseId') || _.get(values, 'cid')}
            />

            <TextInfo
              id="charger-identity-key"
              className={`${className} thirds`}
              label="Identity Key"
              labelProps={{ className }}
              name="identityKey"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'identityKey')}
            />

            <TextInfo
              className={`${className} thirds`}
              disabled={isSubmitting || !altId}
              error={!!(_.get(touched, 'connectionDate') && _.get(errors, 'connectionDate'))}
              helpertext={_.get(touched, 'connectionDate') && _.get(errors, 'connectionDate')}
              id={`${id}-connection-date-input`}
              label="Connection Date"
              labelProps={{ className: `${className} prefilled` }}
              name="connectionDate"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
              type="date"
              value={_.get(values, 'connectionDate')}
            />

            <Dropdown
              className={`${className} thirds`}
              label="Authentication Mode"
              labelProps={{ className }}
              menuProps={{ className }}
              options={shapedStatusOptions}
              value={values && values.authenticationModeId ? Number(values.authenticationModeId) : 214}
              id="charger-authentication-mode"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValues({ ...values, authenticationModeId: e.target.value })
              }
            />

            <TextInfo
              id={`${id}-max-power`}
              error={!!(_.get(touched, 'maxPower') && _.get(errors, 'maxPower'))}
              helpertext={_.get(touched, 'maxPower') && _.get(errors, 'maxPower')}
              className={`${className} thirds`}
              label="Max Power"
              labelProps={{ className }}
              name="maxPower"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'maxPower')}
            />

            <TextInfo
              className={`${className} thirds`}
              disabled={isSubmitting || !altId}
              error={!!(_.get(touched, 'usageStartDate') && _.get(errors, 'usageStartDate'))}
              helpertext={_.get(touched, 'usageStartDate') && _.get(errors, 'usageStartDate')}
              id={`${id}-connection-date-input`}
              label="Usage Start Date"
              labelProps={{ className: `${className} prefilled` }}
              name="usageStartDate"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
              type="date"
              value={_.get(values, 'usageStartDate')}
            />

            <Typography className={`${className}`} variant="h6" component="h2">
              Charger Location
            </Typography>

            <Divider className={className} />

            <Dropdown
              id="charger-location"
              className={`${className} half`}
              label="Location *"
              labelProps={{ className }}
              name="location"
              onBlur={handleBlurDropdown}
              onChange={updateLocation(values, setValues)}
              options={locations}
              value={_.get(values, 'warehouse') || _.get(values, 'locationType') === 'warehouse' ? 'Warehouse' : 'Site'}
            />

            {_.get(values, 'warehouse') || _.get(values, 'locationType') === 'warehouse' ? (
              <Dropdown
                id="warehouse"
                className={`${className} half demo`}
                error={!!(_.get(touched, 'location') && _.get(errors, 'locationId'))}
                helpertext={_.get(touched, 'location') && _.get(errors, 'locationId')}
                label="Warehouse Location *"
                labelProps={{ className }}
                name="locationId"
                onBlur={handleBlurDropdown}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onUpdateWarehouse(target.value)}
                options={warehouseOptions?.map((wh) => ({ label: wh?.warehouseName, value: wh?.altId })) || []}
                value={_.get(values, 'warehouse.altId', 'label')}
              />
            ) : (
              <Dropdown
                id="site"
                className={`${className} half demo`}
                error={!!(_.get(touched, 'location') && _.get(errors, 'locationId'))}
                helpertext={_.get(touched, 'location') && _.get(errors, 'locationId')}
                label="Site *"
                labelProps={{ className }}
                name="locationId"
                onBlur={handleBlurDropdown}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onUpdateSite(target.value)}
                options={siteOptions?.map((site) => ({ label: site?.siteName, value: site?.altId })) || []}
                value={_.get(values, 'site.altId')}
              />
            )}

            <Typography className={className} variant="subtitle1" component="h3">
              Flags
            </Typography>

            <div className={`${className} flags`}>
              {flags.map((flag, i) => (
                <div key={i} className={className}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`${id}-${flag.key}`}
                        className={className}
                        checked={!!_.get(values, flag.key)}
                        name={flag.key}
                        onChange={handleChange}
                        value={_.toString(!_.get(values, flag.key))}
                      />
                    }
                    label={flag.label}
                  />
                </div>
              ))}
            </div>

            {!altId && (
              <Fragment>
                <Typography className={className} variant="h6" component="h2">
                  Charger Hardware
                </Typography>

                <Divider className={className} />

                <Dropdown
                  id="chargerModel"
                  className={`${className} demo half`}
                  error={!!(_.get(touched, 'chargerModel.modelName') && _.get(errors, 'chargerModel.modelName'))}
                  helpertext={_.get(touched, 'chargerModel.modelName') && _.get(errors, 'chargerModel.modelName')}
                  label="Model *"
                  labelProps={{ className }}
                  name="chargerModel"
                  onBlur={handleBlurDropdown}
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onUpdateChargerModel(target.value)}
                  options={
                    modelOptions?.map((model) => ({
                      label: model?.modelName,
                      value: model?.altId,
                      evses: model?.evses,
                    })) || []
                  }
                  value={_.get(values, 'chargerModel.altId')}
                />

                {!altId && _.get(values, 'chargerModel.altId') && <ChargerModelView />}

                {altId && _.get(values, 'chargerModel.altId') && <ChargerModelView />}
              </Fragment>
            )}
          </div>
        </div>

        <Divider />
        {altId && (
          <div className={`${className} additionalDetails`}>
            <Typography className={className} variant="subtitle1" component="h3">
              Additional Charger Details
            </Typography>
            <div className={`${className} additionalCheckboxes`}>
              <div className={className}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`${id}-chargerIsManaged`}
                      className={className}
                      checked={true}
                      name={'chargerIsManaged'}
                      disabled={true}
                    />
                  }
                  label={'Charger is Managed'}
                />
              </div>
              {additionalDetails.map((detail, i) => (
                <div key={i} className={className}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`${id}-${detail.key}`}
                        className={className}
                        checked={!!_.get(values, detail.key)}
                        name={detail.key}
                        onChange={handleChange}
                        value={_.toString(!_.get(values, detail.key))}
                        disabled={
                          detail.key === 'simultaneousChargingEnabled' &&
                          !currentChargerModelDetails?.simultaneousChargeSupported
                        }
                      />
                    }
                    label={detail.label}
                  />
                </div>
              ))}
            </div>
            <div className={`${className} fundingContainer`}>
              <Dropdown
                id={`${id}-fundingSource`}
                className={`${className} third`}
                label="Funding Source"
                labelProps={{ className }}
                name="fundingSource"
                onBlur={handleBlur}
                onChange={handleChange}
                options={fundingSources}
                value={_.get(values, 'fundingSource')}
              />
            </div>

            <div className={`${className} equipmentClass`}>
              <TextInfo
                id={`${id}-equipmentId`}
                className={`${className} third`}
                label="Equipment ID"
                labelProps={{ className }}
                name="equipmentId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={_.get(values, 'equipmentId')}
              />

              <TextInfo
                id={`${id}-description`}
                className={`${className} description`}
                label="Description"
                labelProps={{ className }}
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={_.get(values, 'description')}
              />
            </div>
          </div>
        )}
      </AccordionDetails>

      <Divider />

      {!_.isEmpty(_.get(values, 'tags.edges')) && (
        <div className={`${className} charger-tags`}>
          <Typography className={className} variant="subtitle1" component="h3">
            Charger Tags
          </Typography>

          <Grid className={`${className} tag-container`} id="tagContainer" container spacing={8}>
            {values?.tags?.edges?.map((tag, index) => renderTag(tag, index))}
          </Grid>
        </div>
      )}
    </StyledAccordion>
  );
};
