import {
  Day,
  TariffCustomPricing,
  TariffSchedule,
  UpdateCustomPricingInput,
  UpdatePricingInput,
  TariffInput,
  ReservationTermInput,
} from 'src/@types';
import { DisplayRank } from 'src/@types/shared';

export type FormPricing = {
  pricing: FormPricingPricing;
  displayName?: string;
  displayDescription1?: string;
  displayDescription2?: string;
  displayRank?: number | null;
};

export type FormPricingPricing = {
  altId?: string;
  perKwh: number | false;
  perSession: number | false;
  perMinute: number | false;
};

export type FormReservationFees = {
  fee: number | false;
  noShowFee: number | false;
  enabled?: boolean;
};

export type FormValues = {
  chargerGroupIds: string[];
  altId?: string;
  defaultPricing: FormPricing;
  customPricing: TariffCustomPricing[];
  reservationTerm: FormReservationFees;
};

export function convertTimeToNumber(time: string): number {
  const [hh, mm] = time.split(':');
  const hours = Number(hh);
  const minutes = Number(mm) / 60;
  return hours + minutes;
}

type MappedDay = { scheduleIndex: number; pricingIndex: number; startTime: number; endTime: number };
type OverlapInterval = Omit<MappedDay, 'scheduleIndex' | 'pricingIndex'>;

export function isOverlapping(interval1: OverlapInterval, interval2: OverlapInterval) {
  // if end time is zero, we can assume it's for the next day
  const interval1EndTime = interval1.endTime === 0 ? 24 : interval1.endTime;
  const interval2EndTime = interval2.endTime === 0 ? 24 : interval2.endTime;
  return interval1.startTime < interval2EndTime && interval1EndTime > interval2.startTime;
}

export function getDayOfWeekOverlaps(
  intervals: MappedDay[],
  currentInterval: MappedDay,
  overlaps: Map<string, string[]>,
) {
  intervals.forEach((interval) => {
    if (isOverlapping(interval, currentInterval)) {
      const key = `${interval.pricingIndex}-${interval.scheduleIndex}`;
      const currentKey = `${currentInterval.pricingIndex}-${currentInterval.scheduleIndex}`;

      const value =
        overlaps.has(key) && !overlaps.get(key)?.includes(currentKey)
          ? [...(overlaps.get(key) || []), currentKey]
          : [currentKey];

      overlaps.set(key, value);
    }
  });
}

export function getOverlappingCustomPrices(customPricing: TariffCustomPricing[]) {
  const map = {
    [Day.Monday]: [] as MappedDay[],
    [Day.Tuesday]: [] as MappedDay[],
    [Day.Wednesday]: [] as MappedDay[],
    [Day.Thursday]: [] as MappedDay[],
    [Day.Friday]: [] as MappedDay[],
    [Day.Saturday]: [] as MappedDay[],
    [Day.Sunday]: [] as MappedDay[],
  };

  const overlaps = new Map<string, string[]>();

  customPricing.forEach(({ tariffSchedules }, pricingIndex) => {
    if (!tariffSchedules?.length) {
      return;
    }

    (tariffSchedules as TariffSchedule[]).forEach(({ startTime, endTime, daysOfWeek }, scheduleIndex) => {
      if (!startTime || !endTime || !daysOfWeek?.length) {
        return;
      }

      daysOfWeek.forEach((day) => {
        const interval = {
          pricingIndex,
          scheduleIndex,
          startTime: convertTimeToNumber(startTime),
          endTime: convertTimeToNumber(endTime),
        };

        getDayOfWeekOverlaps(map[day], interval, overlaps);

        map[day].push(interval);
      });
    });
  });

  return overlaps;
}

function convertPrice(price: number | false): number | null {
  return price === false ? null : price;
}

export function convertPricesForMutation(pricing: FormPricing): UpdatePricingInput {
  const { perKwh, perSession, perMinute, ...rest } = pricing?.pricing;
  return {
    perKwh: convertPrice(perKwh),
    perSession: convertPrice(perSession),
    perMinute: convertPrice(perMinute),
    ...rest,
  };
}

export function convertFeesForMutation(pricing: FormReservationFees): ReservationTermInput | null {
  const { fee, noShowFee, enabled, ...rest } = pricing;

  if (!enabled) return null;

  return {
    fee: Number(convertPrice(fee)),
    noShowFee: Number(convertPrice(noShowFee)),
    ...rest,
  };
}

export function transformTariffValues(values: FormValues): TariffInput {
  const {
    defaultPricing: rawDefaultPricing,
    customPricing: rawCustomPricing,
    reservationTerm: rawReservationPricing,
    ...rest
  } = values;

  const { displayRank: defaultRank } = rawDefaultPricing;
  const defaultPricing = {
    ...rawDefaultPricing,
    pricing: convertPricesForMutation(rawDefaultPricing),
    displayRank: defaultRank == DisplayRank.NotDisplayed ? null : defaultRank,
  };
  const reservationTerm = convertFeesForMutation(rawReservationPricing);
  const customPricing = rawCustomPricing.map((customPrice) => {
    const { pricing, displayRank: rank, ...customPricingRest } = customPrice;
    let displayRank = rank;
    if (rank === DisplayRank.NotDisplayed) displayRank = null;
    return { pricing: convertPricesForMutation(customPrice as FormPricing), displayRank, ...customPricingRest };
  }) as UpdateCustomPricingInput[];

  const transformedValues: TariffInput = {
    defaultPricing,
    customPricing,
    ...rest,
  };

  if (reservationTerm) transformedValues.reservationTerm = reservationTerm;

  return transformedValues;
}
