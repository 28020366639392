import { Box, Card, Divider } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useDebounceFn } from 'ahooks';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Driver, DriversV2Sort, useListDriversV2LazyQuery } from 'src/@types';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import { isEmail } from 'src/lib/helpers';
import { useGetFirstUserHost } from 'src/lib/hooks';
import { DriversHeader } from '../DriversHeader';
import { DriversListFilters } from '../DriversListFilters';
import { Styled as StyledTable } from './styles';

export type Filters = {
  search: string;
  accountStatus: string[];
  status: string[];
  accountBillingAddressAdministrativeArea: string[];
};

type ColumnDataProps = {
  data: Driver;
};

const AccountId: React.FC<ColumnDataProps> = ({ data }) => {
  const to = `/accounts/${data?.account?.altId}`;
  return (
    <TableLink to={to}>
      <p data-testid="AccountId">{data?.account?.id}</p>
    </TableLink>
  );
};

const BillingAddress: React.FC<ColumnDataProps> = ({ data }) => {
  const billingAddress = `${data?.account?.billingAddress?.address1 || ''} ${
    data?.account?.billingAddress?.locality ? data?.account?.billingAddress?.locality + ',' : ''
  }  
  ${data?.account?.billingAddress?.administrativeArea || ''}`;
  return <p data-testid="BillingAddress">{billingAddress}</p>;
};

const AccountType: React.FC<ColumnDataProps> = ({ data }) => {
  return data?.driverType === 'OWNER' ? <Done /> : null;
};

const DriverName: React.FC<ColumnDataProps> = ({ data }) => {
  const to = data?.driverType === 'OWNER' ? `/accounts/${data?.account?.altId}` : `/drivers/${data?.altId}`;
  const driverDisplayName =
    data?.firstName || data?.lastName
      ? `${data?.firstName ? data?.firstName : ''} ${data?.lastName ? data?.lastName : ''}`
      : 'N/A';
  return (
    <TableLink to={to}>
      <p data-testid="DriverName">{driverDisplayName}</p>
    </TableLink>
  );
};
const DriverStatus: React.FC<ColumnDataProps> = ({ data }) => {
  return <p>{data?.driverStatus?.columnValue}</p>;
};
const searchFields = ['email', 'firstName', 'lastName', 'accountVehicleVin'];

const columns: Column[] = [
  {
    key: 'firstName',
    label: 'Driver Name',
    sortable: true,
    width: '20%',
    component: DriverName,
    sortName: 'FirstName',
  },
  {
    key: 'accountType',
    label: 'Account Owner',
    align: 'center',
    sortable: false,
    width: '20%',
    component: AccountType,
  },
  {
    key: 'account_id',
    label: 'Account Number',
    sortable: true,
    numeric: true,
    width: '10%',
    component: AccountId,
    sortName: 'AccountId',
  },
  {
    key: 'account.accountStatus',
    label: 'Account Status',
    sortable: true,
    numeric: true,
    width: '10%',
    sortName: 'AccountStatus',
  },
  {
    key: 'driverStatusId',
    label: 'Driver Status',
    sortable: true,
    numeric: true,
    width: '10%',
    component: DriverStatus,
    sortName: 'Status',
  },
  { key: 'email', label: 'Email', sortable: true, numeric: true, width: '10%', sortName: 'Email' },
  {
    key: 'account.billingAddress.address1',
    label: 'Address',
    sortable: true,
    width: '20%',
    component: BillingAddress,
    sortName: 'AccountBillingAddressLine_1',
  },
];

export const DriversList: React.FC = () => {
  const driverOptions = {
    groups: [],
    billingPlan: 'All',
    status: 'All',
    issueType: 'All',
    state: 'All',
  };
  const host = useGetFirstUserHost();
  const [queryParams] = useSearchParams();
  const statusFilterParam = queryParams.get('statusFilter') ?? '';
  const [tableSortBy, setTableSortBy] = React.useState<string>('FirstName');
  const [tableSortDirection, setTableSortDirection] = React.useState<Direction>(Direction.Asc);
  const [listDriversV2Query, { data, loading, error }] = useListDriversV2LazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [search, setTableSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilters] = useState<Filters>({
    search: '',
    accountStatus: [],
    status: String(statusFilterParam) ? [String(statusFilterParam)] : [],
    accountBillingAddressAdministrativeArea: [],
  });
  const { edges } = data?.listDriversV2 || { edges: [] };

  useEffect(() => {
    const updatedSearch: Record<string, unknown> = {};
    const searchValue = filter.search.trim();
    if (searchValue.length) {
      if (!Number.isNaN(Number(searchValue))) {
        updatedSearch.accountId = { eq: searchValue };
      } else if (isEmail(searchValue)) {
        updatedSearch.email = { iLike: `%${searchValue}%` };
      } else {
        searchFields.forEach((field) => {
          updatedSearch[field] = { iLike: `%${searchValue}%` };
        });
      }
    }
    const updatedFilter: Record<string, unknown> = {};
    if (filter) {
      Object.keys(filter).forEach((criteria) => {
        if (filter[criteria as keyof Filters].length && criteria !== 'search')
          updatedFilter[criteria] = { in: filter[criteria as keyof Filters] };
      });
    }

    let updatedSort = null;
    if (tableSortBy) {
      const sortDirection = tableSortDirection === Direction.Asc ? 'Asc' : 'Desc';
      const sortIndex = `${tableSortBy}${sortDirection}` as DriversV2Sort;
      //@ts-ignore Type casting above will be honored
      updatedSort = DriversV2Sort[sortIndex];
    }

    const variables = {
      driversInput: {
        hostId: host?.altId,
        pageSize,
        page,
        search: updatedSearch,
        filter: updatedFilter,
        sort: [updatedSort],
      },
    };

    listDriversV2Query({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    // we deliberatley exclude `filter.search` here, and trigger via a seperate `search` state,
    // so that we can debounce while still updating the UI real time.
    filter.accountStatus,
    filter.accountBillingAddressAdministrativeArea,
    filter.status,
    tableSortBy,
    tableSortDirection,
    host,
    page,
    pageSize,
  ]);

  const { run: debouncedOnFuzzySearchChange } = useDebounceFn(setTableSearch, { wait: 500 });

  return (
    <StyledTable data-testid="DriversList">
      <Box mx={4}>
        <DriversHeader {...driverOptions} data={data} loading={loading} />
        <Box my={4}>
          <Divider />
        </Box>
        <Card>
          <Box p={2}>
            <DriversListFilters
              filters={filter}
              totalResults={data?.listDriversV2?.total || 0}
              onSearch={(value: string) => {
                setFilters({ ...filter, search: value });
                if (value.length === 0 || value.length >= 2) {
                  debouncedOnFuzzySearchChange(value);
                }
              }}
              onFilter={(type) => {
                setFilters(type);
              }}
            />
          </Box>
          <Table
            id="DriversTable"
            data-testid="drivers-table"
            columns={columns}
            data={edges || []}
            loading={loading}
            error={error}
            noDataMessage="There are no drivers matching these filters"
            pagination={{
              total: data?.listDriversV2?.total || 0,
              page: data?.listDriversV2?.page || 0,
              pageSize: data?.listDriversV2?.pageSize || 0,
              rowsPerPageOptions: [10, 25, 100],
              onPageChange: (p: number) => setPage(p),
              onPageSizeChange: (pS: number) => setPageSize(pS),
            }}
            sorting={{
              sortBy: tableSortBy,
              sortDirection: tableSortDirection,
              onSortByChange: setTableSortBy,
              onSortDirectionChange: setTableSortDirection,
            }}
          />
        </Card>
      </Box>
    </StyledTable>
  );
};
