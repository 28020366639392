export const SwitchgearFactory = () => ({
  altId: '1462632c-de35-4247-baa1-c83ecf264925',
  circuits: [],
  flattenedCircuits: [],
  isIntegrated: true,
  manufacturer: 'Tesla',
  model: 'Model',
});

export const CostTariffFactory = () => ({
  altId: '1462632c-de35-4247-baa1-c83ecf264925',
  energyRates: [],
  utilityName: 'Cost Tariff',
  validFromDate: '2020-01-04T00:00:00.000Z',
  validToDate: '2020-01-04T00:00:00.000Z',
});

export const SiteForExtendFactory = () => {
  return {
    altId: '1462632c-de35-4247-baa1-c83ecf269452',
    siteName: 'Jon Test site 3',
    siteStatusId: 123,
    address1: '312 W 5th Street',
    address2: null,
    locality: 'Los angeles',
    administrativeArea: 'CA',
    postalCode: '91411',
    country: 'USA',
    evseCount: 3,
    chargers: {
      edges: [
        {
          altId: '1462632c-de35-4247-baa1-c83ecf264925',
          chargerName: 'Jon Test charger 3',
        },
      ],
      total: 1,
    },
  };
};

export const SiteFactory = () => {
  return {
    id: '14412',
    altId: '1462632c-de35-4247-baa1-c83ecf269452',
    displayName: 'Jon Test site 3',
    address1: '312 W 5th Street',
    address2: null,
    locality: 'Los angeles',
    administrativeArea: 'CA',
    postalCode: '91411',
    country: 'USA',
    siteName: 'Jon Test site 3',
    timeZone: 'America/Los_Angeles',
    property: {
      propertyName: 'Jon Test property 3',
    },
    chargers: {
      edges: [],
      total: 0,
    },
    fleets: [],
    costTariff: CostTariffFactory(),
    switchgear: SwitchgearFactory(),
    utilizationHistory: [],
    scheduleViewTimeHorizon: 30,
    directions: '',
  };
};
