import { TextInfo } from '@evgo/react-material-components';
import { Button, Paper, Radio, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { ModalProps } from 'src/@types/client';
import { DriversWithMeta, useListDriversV2LazyQuery } from '../../../../@types';
import { formatUSNumber, titleCase } from '../../../../lib/helpers';
import { StyledModal } from '../../../shared/StyledModal';
import { Styled } from './styles';

interface Props {
  open: boolean;
  cards?: unknown[];
  selected?: string[];
  assignCards?: () => void;
  assignCardsUpdate?: (input: unknown, assignCard: unknown) => void;
  closeModal?: () => void;
  modalProps?: ModalProps;
  className?: string;
}

export const AssignCardsModal: React.FC<Props> = (props) => {
  const { closeModal, cards, selected, assignCards, assignCardsUpdate } = props;
  const [listDriversV2Query, { data: customersSearchData, loading }] = useListDriversV2LazyQuery({
    fetchPolicy: 'no-cache',
  });

  let customerOptions: DriversWithMeta['edges'];
  let customerTotal = 0;

  if (!loading) {
    customerOptions = customersSearchData?.listDriversV2?.edges || [];
    customerTotal = customersSearchData?.listDriversV2?.total || 0;
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedCustomer, setSelectedCustomer] = React.useState<string>();
  const [customerInput, setCustomerInput] = React.useState('');
  const [showCustomerOptions, setShowCustomerOptions] = React.useState(false);
  const onSearchClick = () => {
    setSelectedCustomer(undefined);
    setShowCustomerOptions(true);
    listDriversV2Query({
      variables: {
        driversInput: { search: { email: { iLike: `%${customerInput}%` }, phone: { iLike: `%${customerInput}%` } } },
      },
    });
  };

  const onCancelClick = () => {
    setActiveStep(0);
    setSelectedCustomer(undefined);
    setCustomerInput('');
    setShowCustomerOptions(false);
    closeModal?.();
  };

  const shouldNextButtonDisabled = () => {
    if (activeStep === 0 && selectedCustomer) {
      return false;
    }

    if (activeStep === 1) {
      return false;
    }

    return true;
  };

  const className = 'assigncardsmodal-component';

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;
  let input = {};

  const selectedCustomerOptions = _.find(customerOptions, { altId: selectedCustomer });

  if (!_.isEmpty(selected) && !_.isEmpty(selectedCustomerOptions)) {
    input = {
      cardIds: selected,
      driverId: selectedCustomerOptions?.altId,
      accountId: selectedCustomerOptions?.account?.altId,
    };
  }

  return (
    <StyledModal
      open={props.open}
      onClose={onCancelClick}
      className={customClass}
      title="Assign Cards"
      primaryButtonText={activeStep === 1 ? 'CONFIRM' : 'NEXT'}
      primaryButtonDisabled={shouldNextButtonDisabled()}
      secondaryButtonText="Cancel"
      secondaryButtonClick={onCancelClick}
      onClick={
        activeStep === 1
          ? () => {
              assignCardsUpdate?.(input, assignCards);
              onCancelClick();
            }
          : () => setActiveStep(activeStep + 1)
      }
    >
      <Styled className={clsx(className, 'content')}>
        {activeStep === 0 ? (
          <div className={`${className} search`}>
            <div className={`${className} search-input`}>
              <TextInfo
                className={className}
                label="Customer"
                value={customerInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerInput(e.target.value)}
                helperText="Search by email or phone number"
                data-testid="assign-cards-modal-customer-search-input"
                variant="filled"
              />

              <Button
                className={className}
                size="large"
                color="secondary"
                variant="contained"
                disabled={!customerInput.trim()}
                data-testid="assign-cards-modal-customer-search-button"
                onClick={onSearchClick}
              >
                ENTER
              </Button>
            </div>

            {showCustomerOptions ? (
              <Fragment>
                <div className={clsx(className, 'customer-header')}>
                  <span className={className}></span>
                  <span className={className}>Name</span>
                  <span className={`${className} email`}>Email Address</span>
                  <span className={`${className} phone`}>Phone Number</span>
                </div>
                <Paper className={clsx(className, 'search-table')}>
                  <Table data-testid="assign-cards-modal-customer-table" className={`${className} customer`}>
                    <TableBody className={className}>
                      {!loading && !customerTotal && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography className={className} variant="body2">
                              No customers found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {!loading &&
                        !!customerTotal &&
                        customerTotal <= 10 &&
                        customerOptions?.map((row, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Radio
                                checked={selectedCustomer === row?.altId}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setSelectedCustomer(e.target.value)
                                }
                                value={row?.altId}
                                color="secondary"
                              />
                            </TableCell>
                            <TableCell>{`${row?.firstName} ${row?.lastName}`}</TableCell>
                            <TableCell>{_.get(row, 'email', '')}</TableCell>
                            <TableCell>{formatUSNumber(_.get(row, 'phone', ''))}</TableCell>
                          </TableRow>
                        ))}

                      {!loading && !!customerTotal && customerTotal > 10 && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography className={className} variant="body2">
                              Too many results returned, please enter a more specific search.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Fragment>
            ) : null}
          </div>
        ) : null}

        {activeStep === 1 ? (
          <div className={`${className} confirmation`} data-testid="assign-cards-modal-confirmation">
            <div>
              <div className={clsx(className, 'customer')}>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Customer
                  </Typography>
                  <Typography className={className} variant="body1">
                    {`${selectedCustomerOptions?.firstName}
                      ${selectedCustomerOptions?.lastName}`}
                  </Typography>
                </div>
                <div className={`${className} email-address`}>
                  <Typography className={className} variant="caption">
                    Email Address
                  </Typography>
                  <Typography className={className} variant="body1">
                    {selectedCustomerOptions?.email}
                  </Typography>
                </div>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Phone Number
                  </Typography>
                  <Typography className={className} variant="body1">
                    {formatUSNumber(selectedCustomerOptions?.phone)}
                  </Typography>
                </div>
              </div>
              <Typography className={clsx(className, 'label')} variant="subtitle1" component="p">
                <sup>*</sup> All assigned cards are automatically activated
              </Typography>
            </div>
            <div className={className}>
              <div className={clsx(className, 'confirm-header')}>
                <span className={className}>External</span>
                <span className={className}>Status</span>
              </div>
              <Paper className={clsx(className, 'search-table')}>
                <Table className={className}>
                  <TableBody>
                    {cards && !_.isEmpty(cards) ? (
                      cards.map((row, key) => (
                        <TableRow key={key}>
                          <TableCell>{_.get(row, 'externalNumber', '')}</TableCell>
                          <TableCell>{titleCase(_.get(row, 'cardStatus', ''))}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Typography className={className} variant="body2">
                        No cards selected.
                      </Typography>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
        ) : null}
      </Styled>
    </StyledModal>
  );
};
