import { gql } from '@apollo/client';

export const upsertTariff = gql`
  mutation upsertTariffForExtend($input: TariffInput!) {
    upsertTariff(input: $input) {
      altId
      id
    }
  }
`;
