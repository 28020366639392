import { gql } from '@apollo/client';

export const listDriversV2 = gql`
  query listDriversV2($driversInput: DriversV2QueryInput) {
    listDriversV2(input: $driversInput) {
      edges {
        altId
        email
        firstName
        lastName
        driverType
        hasPendingAutochargeEnrollment
        driverStatus {
          id
          columnValue
        }
        account {
          altId
          id
          accountStatus
          billingAddress {
            altId
            address1
            address2
            locality
            administrativeArea
            postalCode
            country
          }
        }
      }
      page
      pageSize
      filter {
        driverStatusId {
          in
        }
        account_billingAddress_administrativeArea {
          in
        }
        account_accountStatus {
          in
        }
      }
      search {
        account_id {
          eq
        }
        account_billingAddress_address1 {
          iLike
        }
        email {
          iLike
        }
        firstName {
          iLike
        }
        lastName {
          iLike
        }
      }
      sort {
        account_id
        account_balance
        account_billingAddress_address1
        account_accountStatus
        driverStatusId
        email
        firstName
        lastName
      }
      total
    }
  }
`;
