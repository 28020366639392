import { QueryResult } from '@apollo/client';
import { Button, Divider, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChargersWithMeta } from 'src/@types';
import { Styled } from './styles';

export interface Props {
  fetchmore: QueryResult<never, never>['fetchMore'];
  metadata: ChargersWithMeta;
}

/** Chargers Header component. */
export const ChargersHeader: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargersHeader');
  const className = id;
  const { metadata } = props;

  return (
    <Styled>
      <header className={className}>
        <section className={`${className} title`}>
          <Typography className={className} variant="h5">
            Charger Management
          </Typography>
          <div className={`${className} subtitle`}>{metadata.total} Chargers</div>
        </section>

        <div className={`${className} add-charger`}>
          <Button
            className={className}
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/chargers/new"
          >
            Add Charger
          </Button>
        </div>
      </header>

      <Divider className={className} />
    </Styled>
  );
};
